import React, { useContext, useState } from 'react';
import styles from './Login.module.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import { UserContext } from '../../Context/userContext';

export default function Login() {
  let {setUserTokn,setUserData}= useContext(UserContext);

  let navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isLoading, setloading] = useState(false);
  async function submitLogIn(values) {
    setloading(true);
    let response = await axios.post('https://ecommerce.routemisr.com/api/v1/auth/signin', values)
      .catch((err) => setError(err.response.data.message))
    console.log(response);
    setloading(false);

    if (response.data.message === 'success') {
      navigate('/');
      setloading(false);
      localStorage.setItem('userToken', response.data.token);
      setUserTokn(response.data.token);
      setUserData(response.data.user);
      console.log(setUserTokn);
    }
  }

  let validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),

  });

  let formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: submitLogIn
  });

  return (
    <div className={`container-fluid ${styles.cadCt}`}>
      {error !== null ? <div className='alert alert-primary'>{error}</div> : ""}
      <div className={`container ${styles.card}`}>
        <form onSubmit={formik.handleSubmit} className={`mx-auto ${styles.form}`}>
          <h1 className={styles.title}>Login</h1>

          <div className="mb-3">
            <label htmlFor="email" className="text-white">Email address</label>
            <input
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="email"
              type="email"
              className="form-control"
              value={formik.values.email}
              id="email"
              aria-describedby="emailHelp"
              placeholder="Enter your email"
            />
            {formik.errors.email && formik.touched.email ? <div className="alert text-danger ">{formik.errors.email}</div> : null}
            <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
          </div>

          <div className="mb-3">
            <label htmlFor="password" className="text-white">Password</label>
            <input
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="password"
              type="password"
              className="form-control"
              value={formik.values.password}
              id="password"
              placeholder="Enter your password"
            />
            {formik.errors.password && formik.touched.password ? <div className="alert text-danger ">{formik.errors.password}</div> : null}
          </div>


          <div className="mb-3 form-check">
            <input
              name="terms"
              type="checkbox"
              className="form-check-input"
              id="termsCheck"
            />
            <label className="form-check-label" htmlFor="termsCheck">I agree to the terms and conditions</label>
          </div>

          <button disabled={!(formik.isValid && formik.dirty)} type="submit" className={`${styles.submitBtn}`}>
            {isLoading ? <ScaleLoader color="#16599f" height={19} margin={1} /> : 'Submit'}</button>

          <Link className={`${styles.regiesterBtn} "text-center"`} to={'/Register'} >Or register now </Link>
        </form>
      </div>
    </div>
  );
}
