import React, { useState } from "react";
import Slider from "react-slick";
import slide1 from '../../Assets/images/slide1.webp'
import slide2 from '../../Assets/images/slide2.webp'
import styles from './slider.module.css'
function SlideChangeHooks() {
  const [oldSlide, setOldSlide] = useState(0);
  const [activeSlide, setActiveSlide] = useState(0);
  const [activeSlide2, setActiveSlide2] = useState(0);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => {
      setOldSlide(current);
      setActiveSlide(next);
    },
    afterChange: current => setActiveSlide2(current)
  };
  return (
    <div className="slider-container ">
      {/* <h2>beforeChange and afterChange hooks</h2>
      <p>
        BeforeChange {"=>"} oldSlide: <strong>{oldSlide}</strong>
      </p>
      <p>
        BeforeChange {"=>"} activeSlide: <strong>{activeSlide}</strong>
      </p>
      <p>
        AfterChange {"=>"} activeSlide: <strong>{activeSlide2}</strong>
      </p> */}
      <Slider {...settings}>
        <div>
        <img className={styles.slider} src={slide1} alt="" />
        </div>
        <div>
        <img className={styles.slider} src={slide2} alt="" />
        </div>
        {/* <div>
        <img className={styles.slider} src={slide1} alt="" />
        </div>
        <div>
        <img className={styles.slider} src={slide1} alt="" />
        </div>
        <div>
        <img className={styles.slider} src={slide1} alt="" />
        </div>
        <div>
        <img className={styles.slider} src={slide1} alt="" />
        </div> */}
      </Slider>
    </div>
  );
}

export default SlideChangeHooks;
