import React, { Component } from "react";
import styles from "./CategorySlider.module.css";
import { useQuery } from "react-query";
import Slider from "react-slick";
import productImg3 from '../../Assets/images/makeup.webp'
import productImg4 from '../../Assets/images/cosmetics.webp'

import axios from "axios";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from "react-router-dom";

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCoffee } from '@fortawesome/free-solid-svg-icons';
export default function CategorySlider(props) {
    function Fetching() {
        return axios.get('https://ecommerce.routemisr.com/api/v1/categories');
    }

    let { isLoading, isError, data } = useQuery('fetching', Fetching, {})
    var settings = {
        className: "center",
        infinite: true,
        centerPadding: "60px",
        slidesToShow: 5,
        dots: true,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error loading data</div>;
    }
    return <>

        <div className="slider-container container-xxl mx-auto row text-center my-5 py-5">
            {/* <Slider {...settings}>
                {data?.data?.data.map((item) => (
                    <Link className={`${style.catItem}`}><div className="d-flex flex-column justify-content-center align-items-center" key={item._id}>
                        <img className={`${style.catImg}`} height={120} src={item.image} alt={item.name} />
                        <h3>{item.name}</h3>
                    </div></Link>
                ))}

            </Slider> */}
            <div className='mb-5 '>
                <div className={styles.titCt}>
                    <h2 className={styles.titles}>Categories</h2>
                    <div className={`mb-1 ${styles.line}`}></div>
                    <div className={` ${styles.line1}`}></div>
                </div>
            </div>
            <div className="col-md-6">
                <img src={productImg3} className="w-100" alt="" />
            </div>
            <div className="col-md-6">
                <img src={productImg4} className="w-100" alt="" />
            </div>
        </div>
    </>
}