import { createBrowserRouter } from "react-router-dom";
import Layout from "../Components/Layout/Layout";
import Home from "../Components/Home/Home";
import Products from "../Components/Products/Products";
import Cart from "../Components/Cart/Cart";
import Categories from "../Components/Categories/Categories";
import Brands from "../Components/Brands/Brands";
import Login from "../Components/Login/Login";
import Register from "../Components/Register/Register";
import ProtectedRoute from "../Components/ProtectedRoute/protectedRoute";
import NotFound from "../Components/NotFound/NotFound";
import ProductDetails from "../Components/ProductDetails/ProductDetails";
import Profile from "../Components/profile/profile";


export let  routes = createBrowserRouter([
    {
        path: '/', element: <Layout />, children: [
            { index: true, element: <Home />},
            { path: 'Products', element:<Products /> },
            { path: 'Cart', element:<Cart />},
            { path: 'profile', element:<Profile />},
            { path: 'Categories', element:<Categories />  },
            { path: 'how-to-use', element:<Brands />  },
            { path: 'Product-Details/:id', element:<ProductDetails />  },
            { path: 'Login', element: <Login /> },
            { path: 'Register', element: <Register /> },
            { path: '*', element: <NotFound /> },
        ]
    }
])