import React, { useContext, useEffect } from 'react';
import styles from './Layout.module.css';
import { Outlet } from 'react-router-dom';
import Navbar from '../Navbar/Navbar'
import { UserContext } from '../../Context/userContext';

export default function Layout() {
  let {setUserTokn}= useContext(UserContext);
  useEffect(()=>{
    if (localStorage.getItem('userToken')!==null) {
      setUserTokn(localStorage.getItem('userToken'));
    }},[]
  )
  return <>
  <Navbar/>

  <Outlet></Outlet>
  </>
}
