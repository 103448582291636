import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../../Assets/images/molto-horizontalWhite.PNG'
import styles from './Navbar.module.css';
import { UserContext } from '../../Context/userContext';
export default function Navbar() {
  let { userToken, setUserTokn } = useContext(UserContext);
  let navigate = useNavigate();
  function LogOut() {
    localStorage.removeItem('userToken');
    setUserTokn(null)
    navigate('/login')
  }
  return <>
    <div className={` ${styles.nav}`}>
      <nav className={` navbar navbar-expand-lg container-xxl ${styles.nav}`}>
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src={logo} className={` ${styles.logo}`} alt="fresh cart logo" />
          </Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="22" fill="currentColor" class="bi bi-list text-white" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5" />
            </svg>          </button>
          <div className="text-white collapse navbar-collapse" id="navbarSupportedContent">
            {userToken !== null ? <>
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className={`nav-item ${styles.navs}`}>
                  <Link className={`nav-link ${styles.navLink}`} to="/">Home </Link>
                </li>
                <li className={`nav-item ${styles.navs}`}>
                  <Link className={`nav-link ${styles.navLink}`} to="/products">Products</Link>
                </li>
                <li className={`nav-item ${styles.navs}`}>
                  <Link className={`nav-link ${styles.navLink}`} to="/how-to-use">How to Use</Link>
                </li>
                {/* <li className={`nav-item ${styles.navs}`}>
                  <Link className={`nav-link ${styles.navLink}`} to="/categories">Categories</Link>
                </li>
                <li className={`nav-item ${styles.navs}`}>
                  <Link className={`nav-link ${styles.navLink}`} to="/brands">Brands</Link>
                </li>
                <li className={`nav-item ${styles.navs}`}>
                  <Link className={`nav-link ${styles.navLink}`} to="/profile">Profile</Link>
                </li> */}

              </ul></> : ''}
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item d-flex align-items-center">
                <a className='text-white' href="https://www.facebook.com/MoltoBello.eg" target='_blank'>
                  <i className='fab mx-2 fa-facebook'></i></a>
                <a className='text-white' href="https://www.instagram.com/molto_bello00?igsh=amQxemFmZXphaTdh&utm_source=qr" target="_blank" rel="noopener noreferrer">
                  <i className='fab mx-2 fa-instagram'></i>
                </a>
                {/* <i className='fab mx-2 fa-twitter'></i>
                <i className='fab mx-2 fa-youtube'></i>
                <i className='fab mx-2 fa-tiktok'></i> */}
                {/* <Link className='text-white' to={'/Cart'}>
                  <i className="fa-solid mx-2 fa-cart-shopping"></i>
                </Link> */}
              </li>

              {userToken !== null ? <>
                <li className={`nav-item ${styles.navs}`}>
                  <span className={`nav-link cursor-pointer ${styles.navLink}`} onClick={() => LogOut()} >Logout</span>
                </li>
              </> : <>
                <li className={`nav-item ${styles.navs}`}>
                  <Link className={`nav-link ${styles.navLink}`} to="/login">Login</Link>
                </li>
                <li className={`nav-item ${styles.navs}`}>
                  <Link className={`nav-link ${styles.navLink}`} to="/register">Register</Link>
                </li></>}



            </ul>

          </div>
        </div>
      </nav>
    </div>
  </>
}
