import React, { useContext, useEffect } from "react";
import styles from "./ProductDetails.module.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useQuery } from "react-query";
import { RiseLoader } from "react-spinners";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'
import { CartContext } from "../../Context/cartContext";
import toast from 'react-hot-toast';

// import { faCoffee } from '@fortawesome/free-solid-svg-icons';
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "" }}
            onClick={onClick}
        />
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{ ...style, display: "block", background: "" }}
            onClick={onClick}
        />
    );
}

export default function ProductDetails() {
    let { addToCart } = useContext(CartContext)

    async function sendToCart(productId) {
        let response = await addToCart(productId);
        console.log(response);
        
        if (response?.data.status === 'success') {
            toast.success('Added Successfully')
        }
        else {
            toast.error('not Added')

        }
    }
    let params = useParams();
    async function fetchingProrducts(id) {
        return axios.get(`https://ecommerce.routemisr.com/api/v1/products/${id}`);
    }
    let { isLoading, isError, data } = useQuery('fetchingProrductDetails', () => fetchingProrducts(params.id))
    var settings = {

        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,

    };
    return <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{data?.data?.data.title}</title>
        </Helmet>

        <div className="container mt-4">
            {isLoading ? (
                <div className={`container p-5 mx-auto d-flex justify-content-center ${styles.spinner}`}>
                    <RiseLoader color="#022a2a" />
                </div>
            ) : (
                <div className="row">
                    {data?.data.data ?
                        <div key={data.id} className="col-md-12">
                            <div className={`row ${styles.productCard}`}>
                                <div className="col-lg-4">
                                    <div className={` ${styles.sliderCt}`}>
                                        <Slider {...settings}>
                                            {data?.data?.data?.images?.map((img, index) => (
                                                <div key={index}>
                                                    <img
                                                        className={styles.productImage} // Uncomment and replace with your styles if needed
                                                        src={img}
                                                        alt={data?.data?.data?.title || 'Image'}
                                                    />
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                </div>

                                <div className={`col-lg-8 ${styles.productDetails}`}>
                                    <h5 className={styles.productTitle}>{data.data.data.title}</h5>
                                    <p className={styles.productDescription}>
                                        {data.data.data.description}
                                    </p>
                                    <div className="d-flex flex-column">
                                        <p className={styles.productCategory}>Category: {data.data.data.category.name}</p>
                                        <h5 className={styles.productPrice}>{data.data.data.price} EGP</h5>
                                    </div>
                                    <div className={styles.productRating}>
                                        <span>{data.data.data.ratingsAverage}</span>
                                        <span className="mx-2 fa fa-star"></span>
                                    </div>
                                    <button onClick={() => sendToCart(data.data.data.id)}  className={styles.addToCartButton}>Add to cart  <svg className="mx-1 " xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-cart4" viewBox="0 0 16 16">
                                        <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5M3.14 5l.5 2H5V5zM6 5v2h2V5zm3 0v2h2V5zm3 0v2h1.36l.5-2zm1.11 3H12v2h.61zM11 8H9v2h2zM8 8H6v2h2zM5 8H3.89l.5 2H5zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0m9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0" />
                                    </svg></button>
                                </div>
                            </div>
                        </div> : ''}
                </div>
            )}
        </div>
    </>
}