import { createContext, useState } from "react";

export let  UserContext =createContext();

export default function UserContextProvider(props)
{
    const [userToken,setUserTokn]=useState(null)
    const [userData,setUserData]=useState(null)


    return<UserContext.Provider value={{setUserTokn , userToken ,userData,setUserData}}>
        {props.children}
    </UserContext.Provider>
}