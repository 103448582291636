import './App.css';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { routes } from './Routes/allRouts';
import CounterContextProvider from './Context/counter';
import UserContextProvider from './Context/userContext';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import CartContextProvider from './Context/cartContext';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Toaster } from 'react-hot-toast';
import './index.css';

function App() {
  let queryClient = new QueryClient();
  return <QueryClientProvider client={queryClient}>
    <CartContextProvider>
      <UserContextProvider>
        <RouterProvider router={routes}></RouterProvider>
        <Toaster />
      </UserContextProvider>
      <ReactQueryDevtools initialIsOpen="false" position='bottom-right' />
    </CartContextProvider>
  </QueryClientProvider>
}

export default App;
