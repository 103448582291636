import React from 'react';
import styles from './Brands.module.css';
export default function Footer() {
  return <>

    <footer style={{ backgroundColor: '#333', color: 'white', textAlign: 'center'}}>
      <div className="container">
        <div className="social-media">
          <a className='text-white' href="https://www.facebook.com/MoltoBello.eg" target='_blank'>
            <i className='fab mx-2 fa-facebook' style={{fontSize:'25px'}}></i></a>
          <a className='text-white' href="https://www.instagram.com/molto_bello00?igsh=amQxemFmZXphaTdh&utm_source=qr" target="_blank" rel="noopener noreferrer">
            <i className='fab mx-2 fa-instagram' style={{fontSize:'25px'}}></i>
          </a>
        </div>
        <div style={{ marginTop: '20px' }}>
          <p className='mb-0 pb-2'>© 2024 <a className='text-white mb-0' href="http://loopscopesolutions.com/" target='_blank'>Loop Scope Solutions</a>. All rights reserved.</p>
        </div>
      </div>
    </footer>


  </>
}
