import React, { useContext, useEffect, useState } from 'react';
import styles from './Cart.module.css';
import { CartContext } from '../../Context/cartContext';
import { useQuery } from 'react-query';
import { RiseLoader } from 'react-spinners';

export default function Cart() {
  const { getUserCart, deleteFromCart, updateFromCart } = useContext(CartContext);

  const [cartDetails, setCartDetails] = useState(null)

  const [isLoading, setIsLoading] = useState(true)

  async function getCart() {
    let { data } = await getUserCart();
    setCartDetails(data);
    setIsLoading(false)
    // console.log(data);
  }
  async function removeFromCart(id) {
    let { data } = await deleteFromCart(id);
    setCartDetails(data);
  }
  async function getCount(id ,count) {
    let { data } = await updateFromCart(id ,count);
    setCartDetails(data);
  }
  useEffect(() => {
    getCart()
  }, [])
  return (
    <>
      {isLoading ? (
        <div className={`container p-5 mx-auto d-flex justify-content-center ${styles.spinner}`}>
          <RiseLoader color="#022a2a" />

        </div>
      ) : (<div className="container-xxl px-3  bg-main-light">
        <h1>Cart</h1>
        <h4>{cartDetails?.numOfCartItems}</h4>
        <h5 className=' text-success'>Total Price:{cartDetails?.data.totalCartPrice} $</h5>
        <div className="row mx-1">
          {cartDetails?.data.products.map((product) => <div className="row  border-bottom py-2 ">
            <div className="col-md-1">
              <img className='w-100' src={product?.product.imageCover} alt="" />
            </div>
            <div className="col-md-11">
              <div className="d-flex justify-content-between align-items-center h-100">
                <div className='d-flex flex-column justify-content-center'>
                  <h6>
                    {product?.product.title.split(' ').slice(0, 3).join(' ')}
                  </h6>
                  <h6 className='text-success'>
                    Price : {product?.price} $
                  </h6>
                  <button className='btn text-danger text-start ps-0' onClick={() => removeFromCart(product.product.id)} >
                    <i className='fas mx-2 fa-trash-can'></i>
                  </button>
                </div>
                <div className="d-flex">
                  <button onClick={() => getCount(product.product.id ,product.count +1 )}  className='btn btn-outline-success'>+</button>
                  <span className='mx-2 my-0 d-flex align-items-center'>{product.count}</span>
                  <button onClick={() => getCount(product.product.id ,product.count -1 )} className='btn btn-outline-danger'>-</button>

                </div>
              </div>
            </div>
          </div>
          )}
        </div>
      </div>)
      }

    </>
  );
}
