import React, { useEffect } from 'react';
import Swal from 'sweetalert2';

const OfflineAlert = () => {
  useEffect(() => {
    Swal.fire({
      icon: 'warning',
      title: 'You Are Offline',
      text: 'Please check your internet connection.',
      footer: '<a href="javascript:window.location.reload();">Retry</a>',
    });
  }, []);

  return null; // This component doesn't render anything
};

export default OfflineAlert;
