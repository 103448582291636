import React, { useContext } from 'react';
import styles from './Home.module.css';
import Products from '../Products/Products';
// import categorySlider from '../CategorySlider/CategorySlider'; 
import CategorySlider from '../CategorySlider/CategorySlider';
// import { CounterContext } from '../../Context/counter';
import { Offline, Online } from "react-detect-offline";
import OfflineAlert from '../sweetAlert/sweetAlert';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from "react-helmet";
import logo from '../../../src/Assets/images/molto.PNG'
import Slider from '../slider/slider';
import ProductsSlider from '../products-slider/products-slider';
import Brands from '../Brands/Brands';
import Footer from '../Footer/Footer';
export default function Home() {
  // let {ChangeCounter,counter}= useContext(CounterContext);
  const isOffline = !navigator.onLine;

  return <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Molto Bello For Cosmetic & Makeup
      </title>
      <meta name="og:image" content="../../Assets/images/molto.jpg" />
      <meta property="og:title" content="Molto Bello For Cosmetic & Makeup " />
      <meta property="og:description" content="We have a cosmetic line and makeup line 
And we started with only one product to prove ourselves in the market first And then we will offer the whole collection insha’allah, 
Our first product is  “Makhamria Serum” Freshener and moisturizer for body and hair"/>
    </Helmet>
    <div className="App container-fluid">
      {isOffline && <OfflineAlert />}
      {/* The rest of your app goes here */}

    </div>
    <div className={`position-absolute p-3 ${styles.offlineCt}`}>
      <Offline >
        <div className={` border-2 p-3 ${styles.offline}`}>
          <i className='fas fa-wifi mx-2'></i>You Are Offline !?
        </div>
      </Offline>
    </div>
    <Slider />
    <CategorySlider />
    <ProductsSlider />
    <Footer />


  </>
}
