import React, { useState } from 'react';
import styles from './Register.module.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import Login from '../Login/Login';
import { ScaleLoader } from 'react-spinners';

export default function Register() {
  let navigate = useNavigate();
  const [error, setError] = useState(null);
  const [isLoading, setloading] = useState(false);
  async function submitRegister(values) {
    setloading(true);
    let response = await axios.post('https://ecommerce.routemisr.com/api/v1/auth/signup', values)
      .catch((err) => setError(err.response.data.message))
    console.log(response);
    setloading(false);

    if (response.data.message === 'success') {
      navigate('/Login')
    }
  }

  let validationSchema = Yup.object({
    name: Yup.string().min(3, 'Name must be at least 3 characters').required('Name is required'),
    phone: Yup.string().matches(/^[0-9]+$/, 'Phone number must be numeric').required('Phone number is required'),
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string().min(6, 'Password must be at least 6 characters').required('Password is required'),
    rePassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm password is required')
  });

  let formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      email: '',
      password: '',
      rePassword: ''
    },
    validationSchema: validationSchema,
    onSubmit: submitRegister
  });

  return (
    <div className="row container-xxl mx-auto">
        {error !== null ? <div className='alert alert-primary'>{error}</div> : ""}
        <div className={`container col-lg-6 ${styles.card}`}>
        <form onSubmit={formik.handleSubmit} className={`mx-auto ${styles.form}`}>
          <h1 className={styles.title}>Sign Up</h1>

          <div className="mb-3">
            <label htmlFor="name" className="text-white">Name</label>
            <input
              id="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control"
              name="name"
              type="text"
              value={formik.values.name}
              placeholder="Enter your name"
            />
            {formik.errors.name && formik.touched.name ? <div className="text-danger mb-0 ">{formik.errors.name}</div> : null}
          </div>

          <div className="mb-3">
            <label htmlFor="phone" className="text-white">Phone</label>
            <input
              id="phone"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className="form-control"
              name="phone"
              type="tel"
              value={formik.values.phone}
              placeholder="Enter your phone number"
            />
            {formik.errors.phone && formik.touched.phone ? <div className="text-danger mb-0 ">{formik.errors.phone}</div> : null}
          </div>

          <div className="mb-3">
            <label htmlFor="email" className="text-white">Email address</label>
            <input
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="email"
              type="email"
              className="form-control"
              value={formik.values.email}
              id="email"
              aria-describedby="emailHelp"
              placeholder="Enter your email"
            />
            {formik.errors.email && formik.touched.email ? <div className="text-danger mb-0 ">{formik.errors.email}</div> : null}
            <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div>
          </div>

          <div className="mb-3">
            <label htmlFor="password" className="text-white">Password</label>
            <input
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="password"
              type="password"
              className="form-control"
              value={formik.values.password}
              id="password"
              placeholder="Enter your password"
            />
            {formik.errors.password && formik.touched.password ? <div className="text-danger mb-0 ">{formik.errors.password}</div> : null}
          </div>

          <div className="mb-3">
            <label htmlFor="rePassword" className="text-white">Re-Password</label>
            <input
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="rePassword"
              type="password"
              className="form-control"
              value={formik.values.rePassword}
              id="rePassword"
              placeholder="Confirm your password"
            />
            {formik.errors.rePassword && formik.touched.rePassword ? <div className="text-danger mb-0 ">{formik.errors.rePassword}</div> : null}
          </div>

          <div className="mb-3 form-check">
            <input
              name="terms"
              type="checkbox"
              className="form-check-input"
              id="termsCheck"
            />
            <label className="form-check-label" htmlFor="termsCheck">I agree to the terms and conditions</label>
          </div>

          <button disabled={!(formik.isValid && formik.dirty)} type="submit" className={`${styles.submitBtn}`}>
            {isLoading ? <ScaleLoader color="#16599f" height={19} margin={1} /> : 'Submit'}</button>

          <Link className={`${styles.regiesterBtn} "text-center"`} to={'/Login'} >Already have an account  </Link>

        </form>
      </div>
      <div className={`container-fluid col-lg-6 ${styles.cadCt}`}>

      </div>
     
    </div>
  );
}
