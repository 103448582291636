import axios from "axios";
import { createContext, useState } from "react";
import Products from "../Components/Products/Products";

export let CartContext = createContext();
let headers = {
    token: localStorage.getItem('userToken')
}
export default function CartContextProvider(props) {
    function addToCart(productId) {
        return axios.post(`https://ecommerce.routemisr.com/api/v1/cart`,
            // body of the request
            {
                productId: productId,
            },
            // headers of request
            {
                headers: headers
            }).then((response) => response)
            .catch((error) => error)

    }
    function getUserCart() {
        return axios.get(`https://ecommerce.routemisr.com/api/v1/cart`,
            // get dosn't have ody
            // headers of request
            {
                headers: headers
            }).then((response) => response)
            .catch((error) => error)

    }
    function deleteFromCart(productId) {
        return axios.delete(`https://ecommerce.routemisr.com/api/v1/cart/${productId}`,

            {
                headers: headers
            }).then((response) => response)
            .catch((error) => error)

    }
    function updateFromCart(productId, count) {
        return axios.put(`https://ecommerce.routemisr.com/api/v1/cart/${productId}`,
            {
                count: count
            },
            {
                headers: headers
            }).then((response) => response)
            .catch((error) => error)

    }
    return <CartContext.Provider value={{ addToCart, getUserCart, deleteFromCart,updateFromCart }}>
        {props.children}
    </CartContext.Provider>
}