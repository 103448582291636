// import React, { useContext } from "react";
// import toast from 'react-hot-toast';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import axios from "axios";
// import { useQuery } from "react-query";
// import { ScaleLoader } from "react-spinners";
// import { Link } from "react-router-dom";
// import { CartContext } from "../../Context/cartContext";
import productImg from '../../Assets/images/product.png'
import productImg2 from '../../Assets/images/product2.png'
import productImg3 from '../../Assets/images/product3.png'
import productImg4 from '../../Assets/images/product4.png'
import productImg5 from '../../Assets/images/product5.png'
import one from '../../../src/Assets/images/1.svg'
import productImg6 from '../../Assets/images/product6.png'
// // import "slick-carousel/slick/slick.css";
// // import "slick-carousel/slick/slick-theme.css";
// import Slider from "react-slick";
// import styles from "./products-slider.module.css";

// function NextArrow(props) {
//     const { className, style, onClick } = props;
//     return (
//         <div
//             className={`${className} ${styles.customArrow}`}
//             style={{ ...style, display: "block", right: "10px" }}
//             onClick={onClick}
//         />
//     );
// }

// function PrevArrow(props) {
//     const { className, style, onClick } = props;
//     return (
//         <div
//             className={`${className} ${styles.customArrow}`}
//             style={{ ...style, display: "block", left: "-5px", zIndex: 1 }}
//             onClick={onClick}
//         />
//     );
// }
// export default function ProductsSlider() {
//     const { addToCart } = useContext(CartContext);

//     // Fetch products from the API
//     function Fetching() {
//         return axios.get('https://ecommerce.routemisr.com/api/v1/products');
//     }

//     // Add product to cart
//     async function sendToCart(productId) {
//         let response = await addToCart(productId);
//         if (response?.data.status === 'success') {
//             toast.success('Added Successfully');
//         } else {
//             toast.error('Not Added');
//         }
//     }

//     let { isLoading, data } = useQuery('fetchingProrduct', Fetching, {});

//     // Slider settings with custom arrows
//     const settings = {
//         // dots: true,
//         infinite: true,
//         speed: 500,
//         autoplay: true,
//         autoplaySpeed: 3000,
//         pauseOnHover: true,
//         slidesToShow: 4,
//         slidesToScroll: 1,
//         nextArrow: <NextArrow />,
//         prevArrow: <PrevArrow />,
//         // customPaging: (i) => (
//         //     <div className={styles.customDot}></div>
//         // ),
//         // appendDots: (dots) => (
//         //     <ul style={{ display: "flex", justifyContent: "center" }}>
//         //         {dots.slice(0, 5)} {/* Show only the first 5 dots */}
//         //     </ul>
//         // ),
//         responsive: [
//             {
//                 breakpoint: 1024,
//                 settings: {
//                     slidesToShow: 3,
//                     slidesToScroll: 1,
//                     infinite: true,
//                     dots: true,
//                 },
//             },
//             {
//                 breakpoint: 600,
//                 settings: {
//                     slidesToShow: 2,
//                     slidesToScroll: 1,
//                 },
//             },
//             {
//                 breakpoint: 480,
//                 settings: {
//                     slidesToShow: 1,
//                     slidesToScroll: 1,
//                 },
//             },
//         ],
//     };

//     return (
//         <div className="container mt-4">
//             <div className="row container-xxl mx-auto">
//                 <div>
//                     <div className={styles.titCt}>
//                         <h2 className={styles.titles}>Products</h2>
//                         <div className={`mb-1 ${styles.line}`}></div>
//                         <div className={` ${styles.line1}`}></div>
//                     </div>
//                 </div>
//                 {isLoading ? (
//                     <div className="d-flex justify-content-center">
//                         <ScaleLoader color="#16599f" height={19} margin={1} />
//                     </div>
//                 ) : (
//                     <Slider {...settings}>
//                         {data?.data.data.map((item) => (
//                             <div key={item._id} className="d-flex justify-content-center">
//                                 <div className={styles.card}>
//                                     <div className={styles.imgBx}>
//                                         {/* <img src={item.imageCover} alt={item.title} /> */}
//                                         <img src={productImg} alt={item.title} />
//                                     </div>
//                                     <div className={styles.contentBx}>
//                                         <h2>{item.title.split(' ').slice(0, 2).join(' ')}</h2>
//                                         {/* <h2>{item.category.name}</h2> */}
//                                         <div className={styles.color}>
//                                             <h3>{item.category.name}</h3>
//                                             {/* <span></span>
//                                             <span></span>
//                                             <span></span> */}
//                                         </div>
//                                         <div className={styles.actionButtons}>
//                                             <Link to={`/Product-Details/${item._id}`}>Show</Link>
//                                             <button onClick={() => sendToCart(item._id)}>
//                                                 {isLoading ? (
//                                                     <ScaleLoader color="#16599f" height={19} margin={1} />
//                                                 ) : (
//                                                     <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-cart4" viewBox="0 0 16 16">
//                                                         <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5M3.14 5l.5 2H5V5zM6 5v2h2V5zm3 0v2h2V5zm3 0v2h1.36l.5-2zm1.11 3H12v2h.61zM11 8H9v2h2zM8 8H6v2h2zM5 8H3.89l.5 2H5zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0m9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0" />
//                                                     </svg>
//                                                 )}
//                                             </button>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         ))}
//                     </Slider>
//                 )}
//             </div>
//         </div>
//     );
// }
import React from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import styles from "./products-slider.module.css";

const ProductSlider = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="container-xxl mx-auto mt-4 ">
            <div className="row w-100 mx-auto">
                <div className='mb-5'>
                    <div className={styles.titCt}>
                        <h2 className={styles.titles}>Products</h2>
                        <div className={`mb-1 ${styles.line}`}></div>
                        <div className={` ${styles.line1}`}></div>
                    </div>
                </div>
                <Slider {...settings}>
                    <div className="d-flex justify-content-center">
                        <div className={styles.card}>
                            <div className={styles.imgBx}>
                                <img src={productImg} alt="Product Two" />
                            </div>
                            <div className={styles.contentBx}>
                                <h2>Roma</h2>
                                <div className={styles.color}>
                                    <h3>Cosmetics</h3>
                                </div>
                                <div className={styles.actionButtons}>
                                    <Link to="/Product-Details/1">Show</Link>
                                    <button>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="25"
                                            height="25"
                                            fill="currentColor"
                                            className="bi bi-cart4"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5M3.14 5l.5 2H5V5zM6 5v2h2V5zm3 0v2h2V5zm3 0v2h1.36l.5-2zm1.11 3H12v2h.61zM11 8H9v2h2zM8 8H6v2h2zM5 8H3.89l.5 2H5zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0m9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex justify-content-center">
                        <div className={styles.card}>
                            <div className={styles.imgBx}>
                                <img src={productImg2} alt="Product Two" />
                            </div>
                            <div className={styles.contentBx}>
                                <h2>Siena</h2>
                                <div className={styles.color}>
                                    <h3>Cosmetics</h3>
                                </div>
                                <div className={styles.actionButtons}>
                                    <Link to="#">Show</Link>
                                    <button>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="25"
                                            height="25"
                                            fill="currentColor"
                                            className="bi bi-cart4"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5M3.14 5l.5 2H5V5zM6 5v2h2V5zm3 0v2h2V5zm3 0v2h1.36l.5-2zm1.11 3H12v2h.61zM11 8H9v2h2zM8 8H6v2h2zM5 8H3.89l.5 2H5zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0m9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Add more static slides as needed */}
                    <div className="d-flex justify-content-center">
                        <div className={styles.card}>
                            <div className={styles.imgBx}>
                                <img src={productImg3} alt="Product Two" />
                            </div>
                            <div className={styles.contentBx}>
                                <h2>Torino</h2>
                                <div className={styles.color}>
                                    <h3>Cosmetics</h3>
                                </div>
                                <div className={styles.actionButtons}>
                                    <Link to="#">Show</Link>
                                    <button>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="25"
                                            height="25"
                                            fill="currentColor"
                                            className="bi bi-cart4"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5M3.14 5l.5 2H5V5zM6 5v2h2V5zm3 0v2h2V5zm3 0v2h1.36l.5-2zm1.11 3H12v2h.61zM11 8H9v2h2zM8 8H6v2h2zM5 8H3.89l.5 2H5zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0m9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className={styles.card}>
                            <div className={styles.imgBx}>
                                <img src={productImg4} alt="Product Two" />
                            </div>
                            <div className={styles.contentBx}>
                                <h2>Napoli</h2>
                                <div className={styles.color}>
                                    <h3>Cosmetics</h3>
                                </div>
                                <div className={styles.actionButtons}>
                                    <Link to="#">Show</Link>
                                    <button>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="25"
                                            height="25"
                                            fill="currentColor"
                                            className="bi bi-cart4"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5M3.14 5l.5 2H5V5zM6 5v2h2V5zm3 0v2h2V5zm3 0v2h1.36l.5-2zm1.11 3H12v2h.61zM11 8H9v2h2zM8 8H6v2h2zM5 8H3.89l.5 2H5zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0m9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className={styles.card}>
                            <div className={styles.imgBx}>
                                <img src={productImg5} alt="Product Two" />
                            </div>
                            <div className={styles.contentBx}>
                                <h2>Venezia</h2>
                                <div className={styles.color}>
                                    <h3>Cosmetics</h3>
                                </div>
                                <div className={styles.actionButtons}>
                                    <Link to="#">Show</Link>
                                    <button>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="25"
                                            height="25"
                                            fill="currentColor"
                                            className="bi bi-cart4"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5M3.14 5l.5 2H5V5zM6 5v2h2V5zm3 0v2h2V5zm3 0v2h1.36l.5-2zm1.11 3H12v2h.61zM11 8H9v2h2zM8 8H6v2h2zM5 8H3.89l.5 2H5zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0m9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center">
                        <div className={styles.card}>
                            <div className={styles.imgBx}>
                                <img src={productImg6} alt="Product Two" />
                            </div>
                            <div className={styles.contentBx}>
                                <h2>Milano</h2>
                                <div className={styles.color}>
                                    <h3>Cosmetics</h3>
                                </div>
                                <div className={styles.actionButtons}>
                                    <Link to="#">Show</Link>
                                    <button>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="25"
                                            height="25"
                                            fill="currentColor"
                                            className="bi bi-cart4"
                                            viewBox="0 0 16 16"
                                        >
                                            <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5M3.14 5l.5 2H5V5zM6 5v2h2V5zm3 0v2h2V5zm3 0v2h1.36l.5-2zm1.11 3H12v2h.61zM11 8H9v2h2zM8 8H6v2h2zM5 8H3.89l.5 2H5zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0m9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0" />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </div>
            <div className="my-5">
                <div className='mb-5'>
                    <div className={styles.titCt}>
                    <h2 className={`my-5 ${styles.titles}`}>How To Use</h2>
                    <div className={`mb-1 ${styles.line}`}></div>
                        <div className={` ${styles.line1}`}></div>
                    </div>
                </div>
                <div className="row container-xxl">
                    <img className='col-md-6' src={one} alt="" />
                    <div className=' col-md-6' style={{ position: 'relative', height: '100%' }}>
                        <iframe
                            src="https://iframe.mediadelivery.net/embed/305145/8c7a8387-24e5-4e79-bcbb-3162c9188129?autoplay=true&loop=false&muted=false&preload=true&responsive=true"
                            loading="lazy"
                            style={{ border: '0', position: '', top: '0', height: '600px', width: '100%' }}
                            allow="accelerometer; gyroscope; encrypted-media; picture-in-picture"
                            allowFullScreen={true}>
                        </iframe>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default ProductSlider;
