import React, { useContext } from "react";
import style from "./profile.module.css";
import { jwtDecode } from 'jwt-decode'; 
import { UserContext } from '../../Context/userContext';

export default function Profile() {
    let encodedToken = localStorage.getItem('userToken');
    let decodedToken = jwtDecode(encodedToken);
    let {userData}=useContext(UserContext)
    
    return (
        <>
            <h1>name: {decodedToken.name}</h1>
            <h1>email: {userData?.email}</h1>
        </>
    );
}
