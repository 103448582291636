import React, { useContext } from "react";
import toast from 'react-hot-toast';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from "axios";
import { useQuery } from "react-query";
import { RiseLoader, ScaleLoader } from "react-spinners";
import { Link } from "react-router-dom";
import { CartContext } from "../../Context/cartContext";
import styles from './Products.module.css';

import productImg from '../../Assets/images/product.png'
import productImg2 from '../../Assets/images/product2.png'
import productImg3 from '../../Assets/images/product3.png'
import productImg4 from '../../Assets/images/product4.png'
import productImg5 from '../../Assets/images/product5.png'
import productImg6 from '../../Assets/images/product6.png'
export default function Products() {
  let { addToCart } = useContext(CartContext);

  function Fetching() {
    return axios.get('https://ecommerce.routemisr.com/api/v1/products');
  }

  async function sendToCart(productId) {
    let response = await addToCart(productId);
    if (response?.data.status === 'success') {
      toast.success('Added Successfully');
    } else {
      toast.error('Not Added');
    }
  }

  let { isLoading, data } = useQuery('fetchingProrduct', Fetching, {});

  return (
    <div className={styles.container}>

      {isLoading ? (
        <div className={`container p-5 mx-auto d-flex justify-content-center ${styles.spinner}`}>
          <RiseLoader color="#022a2a" />
        </div>
      ) : (
        <div className="row container-xxl mx-auto">
          <div className='my-3'>
            <div className={styles.titCt}>
              <h2 className={styles.titles}>Products</h2>
              <div className={`mb-1 ${styles.line}`}></div>
              <div className={` ${styles.line1}`}></div>
            </div>
          </div>
          {[productImg, productImg2, productImg3, productImg4, productImg5, productImg6].map((imgSrc, index) => (
            <div key={index} className="col-lg-3 col-md-4 my-4 px-3 d-flex justify-content-center">
              <div className={styles.card}>
                <div className={styles.imgBx}>
                  <img src={imgSrc} alt={`Product ${index + 1}`} />
                </div>
                <div className={styles.contentBx}>
                  <h2>{["Roma", "Siena", "Torino", "Napoli", "Venezia", "Florence"][index]}</h2>
                  <div className={styles.color}>
                    <h3>Cosmetics</h3>
                  </div>
                  <div className={styles.actionButtons}>
                    <Link to={`/Product-Details/${index + 1}`}>Buy Now</Link>
                    <button>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        fill="currentColor"
                        className="bi bi-cart4"
                        viewBox="0 0 16 16"
                      >
                        <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5M3.14 5l.5 2H5V5zM6 5v2h2V5zm3 0v2h2V5zm3 0v2h1.36l.5-2zm1.11 3H12v2h.61zM11 8H9v2h2zM8 8H6v2h2zM5 8H3.89l.5 2H5zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0m9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

      )}
    </div>
  );
}
